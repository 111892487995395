import * as React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import TutorAccordion from "./tutorFilter/TutorAccordion";
import {
  TextField,
  MenuItem,
  Chip,
  Autocomplete,
  Checkbox,
  Box,
  Button,
  createFilterOptions,
  FormControlLabel,
  Modal,
} from "@mui/material";
// import { Button, TextField, Icon, Modal, Box, FormControlLabel, Checkbox } from "@mui/material";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import {
  getTutors,
  getTutorsPage,
  getSingleTutor,
  searchTutors,
  getClasses,
  getSubjects,
} from "../actions/formdata";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Logo from "../assets/img/brand/logo.svg";

import HeroImage1 from "../assets/img/tutors/tutorHero-1.png";
import HeroImage2 from "../assets/img/tutors/tutorHero-2.png";

import { useFormik } from "formik";
import * as yup from "yup";

import Rating from "../assets/img/tutors/rating.svg";
import Search from "../assets/img/brand/search-icon.svg";

import { Subjects } from "../content";

import { Pagination } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //border: '1px solid #CFCFCF',
  //boxShadow: 24,
  p: 4,
};

const subjects = Subjects;

const validationSchema = yup.object({
  searchParameter: yup.string(),
});

const TutorsPage = () => {
  //API CALLS

  const dispatch = useDispatch();
  // const stateValues = useSelector((state) => state);

  useEffect(() => {
    dispatch(getClasses());
    dispatch(getSubjects());
    dispatch(getTutors("none"));
  }, []);

  const classes = useSelector((state) => state.classes);
  let classesOptions = [];
  if (!classes.loading) {
    //console.log(classes)
    classesOptions = classes.data.sort(
      (a, b) => parseFloat(a.orderNo) - parseFloat(b.orderNo)
    );
  }

  const subjects = useSelector((state) => state.subjects);
  let subjectOptions = [];
  //subjectOptions = subjects.data;
  if (!subjects.loading) {
    //console.log(classes)
    subjectOptions = subjects.data;
  }

  const tutorsData = useSelector((state) => state.tutors.data);
  // console.log(tutorsData);
  // let searchDataOptions = [];
  // if(!searchData.loading) {
  //     searchDataOptions = searchData.data;
  // }

  let [tutorsList, setTutorsList] = useState([]);

  const [tutorSearchList, setTutorSearchList] = useState(null);
  const [singleTutor, setSingleTutor] = useState(null);

  useEffect(() => {
    if (tutorsData) {
      setTutorsList(tutorsData.data);
    }
  }, [tutorsData]);

  const searchData = useSelector((state) => state.searchTutors.data);
  useEffect(() => {
    if (searchData) {
      setTutorsList(searchData.data);
    }
  }, [searchData]);

  const [subjectVal, setSubjectVal] = useState(null);
  const [classCat, setClassCat] = useState(null);

  const handleChange = (event, value) => {
    dispatch(getTutorsPage(value)).then(() => {
      setTutorsList(tutorsData.data);
      document.getElementById("tutorsWrap").scrollIntoView();
    });
  };

  //Tutor search function
  const filterTutors = (parameter, searchType) => {
    //parameter = parameter.toLowerCase().split(" ")[0];
    if (parameter) {
      if (searchType == "subject") {
        if (classCat) {
          dispatch(searchTutors(parameter, classCat.name)).then(() => {
            //setTutorsList(searchData.data);
            console.log(classCat);
          });
        } else {
          dispatch(searchTutors(parameter, "")).then(() => {
            //setTutorsList(searchData.data);
            console.log(classCat);
          });
        }
      } else if (searchType == "class") {
        if (subjectVal) {
          dispatch(searchTutors(subjectVal.name, parameter)).then(() => {
            //setTutorsList(searchData.data);
            console.log(subjectVal);
          });
        } else {
          dispatch(searchTutors("", parameter)).then(() => {
            //setTutorsList(searchData.data);
            console.log(subjectVal);
          });
        }
      }
    } else {
      dispatch(getTutors("none")).then(() => {
        //const tutorsData = useSelector((state) => state.tutors.data);
        setTutorsList(tutorsData.data);
      });
    }
  };

  const clearSearch = () => {
    setSubjectVal("");
    setClassCat("");
    dispatch(getTutors("none"));
  };

  // Add Modal state to tutorList array
  //tutorsList.map(v => ({...v, modalOpen: false}));

  const formik = useFormik({
    initialValues: {
      searchParameter: "",
    },
    validationSchema: validationSchema,
  });

  //Modal Use State Function
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  //Tutor Modal Use State Function
  //let tutorModalState = tutorsList.map((item) => { "modalState" = false })
  const [tutorModalOpen, setTutorModalOpen] = useState(false);
  const handleTutorOpen = (tutorId) => {
    dispatch(getSingleTutor(tutorId));
    setTutorModalOpen(true);
  };
  const handleTutorClose = () => setTutorModalOpen(false);

  //Set Subject Coding Tutor Value
  const [subjectChecked, setSubjectChecked] = useState(false);
  const handleSubjectChange = (event) => {
    setSubjectChecked(event.target.checked);
    setCodingChecked(!event.target.checked);
  };

  //Set Coding Coding Tutor Value
  const [codingChecked, setCodingChecked] = useState(false);
  const handleCodingChange = (event) => {
    setCodingChecked(event.target.checked);
    setSubjectChecked(!event.target.checked);
  };

  const navigate = useNavigate();
  const handleSelection = () => {
    if (subjectChecked) {
      navigate("/subject-tutor-request-form");
    } else if (codingChecked) {
      navigate("/coding-tutor-request-form");
    }
  };

  const tutorModalData = useSelector((state) => state.singleTutor.data);
  //const searchData = useSelector((state) => state.searchTutors.data);
  //console.log(tutorModalData);

  return (
    <div className="page-content bkg-pale-yellow pgx pgy">
      <div className="subPages row justify-content-between align-items-center">
        <div className="col-lg-6">
          <div className="hdr xl mb-3 mb-lg-3">
            Tutoring just got an upgrade.
          </div>
          <div className="only-mobile double py-3 hasBlur justify-content-between align-items-center">
            <div className="box-card md clr-pink">
              <img src={HeroImage1} alt="" />
            </div>
            <div className="box-card lg clr-purple">
              <img src={HeroImage2} alt="" />
            </div>
          </div>
          <div className="text pe-lg-5">
            <p>
              Nigenius Tutors leverage online digital resources from our
              platform to give your kids an amazing learning experience and
              excel.
            </p>
          </div>
          <div className="btnHld mt-4 mt-lg-5">
            <Button
              className="hasIcon mainCta caps"
              fullWidth
              type="button"
              variant="contained"
              onClick={handleOpen}
            >
              Request a Tutor Now <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>

          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBox" sx={style}>
              <div onClick={handleClose} className="closeModal">
                <div className="closeBtn"></div>
              </div>
              <div className="modalDetails">
                <div className="hdr lg dark">
                  What type of tutor do you need?
                </div>
                <div className="modalAction" id="modal-modal-description">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={codingChecked}
                        onChange={handleCodingChange}
                      />
                    }
                    label="Coding Tutor"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subjectChecked}
                        onChange={handleSubjectChange}
                      />
                    }
                    label="Subject Tutor"
                  />
                </div>
                <Button
                  className="hasIcon mainCta caps"
                  fullWidth
                  type="button"
                  variant="contained"
                  onClick={handleSelection}
                >
                  Continue to tutor request{" "}
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
        <div className="col-lg-5 only-desktop double hasBlur justify-content-between align-items-center">
          <div className="box-card md clr-pink">
            <img src={HeroImage1} alt="" />
          </div>
          <div className="box-card lg clr-purple">
            <img src={HeroImage2} alt="" />
          </div>
        </div>
      </div>

      {/* <div className='searchSec row justify-content-center align-items-center mt-lg-5'>
                <div className='col-lg-10'>
                    <div className='hdr xl text-center mb-lg-3'>Search for a tutor</div>

                    <div className='searchWrap'>
                        <TextField
                            fullWidth
                            className='textField'
                            id="keySearch"
                            name="firstName" 
                            type="text" 
                            placeholder="Enter a Subject"
                            variant="outlined"
                            //error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        />  
                        <Button 
                            className="cta hasIcon"
                            fullWidth 
                            type="button"
                            variant="contained"
                            sx={{
                                width: 'auto',
                                fontSize: '1.2rem',
                                gap: '15px',
                                fontWeight: '700',
                                padding: "12px 35px",
                                //textTransform: "capitalize",
                                backgroundColor: "#01C38E",
                                ':hover': {
                                    backgroundColor: '#01C38E',
                                    opacity: '0.8'
                                },
                            }}
                        >Search for a tutor <FontAwesomeIcon icon={faArrowRight} /></Button>
                    </div>
                </div>
                <div className='col-lg-12'>
                    <ul className='quickSearch'>
                        {subjects.map((v) => (
                            <li>
                                <img src={Search} alt="" />
                                <span>{v.title}</span>
                            </li> 
                        ))}
                    </ul>
                </div>
            </div> */}

      <div className="statsWrap bkg-light-grey">
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={486} />
          </div>
          <div className="statTitle">Active Students</div>
        </div>
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={500} />
          </div>
          <div className="statTitle">Expert Tutors</div>
        </div>
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={6350} />
          </div>
          <div className="statTitle">Hours Tutored</div>
        </div>
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={168} />
          </div>
          <div className="statTitle">Learning Reports</div>
        </div>
      </div>

      <div className="tutorGalleryWrap">
        <div className="row row justify-content-between align-items-start">
          <div className="col-lg-4">
            <div className="hdr xl mb-lg-3">View Our Tutor Gallery</div>

            <div className="filterSec">
              <div className="hdr md mb-lg-2">Filters</div>
              <div onClick={() => clearSearch()} className="clearFilters">
                Clear Filters <span>&times;</span>
              </div>
              <div className="filterWrap">
                <ul className="accordion tutorFilter">
                  <li className="accordionItem pt-lg-3 pt-2">
                    <button className="button">
                      <span className="mb-lg-2 mb-1">Search by Subject</span>
                      <span className="control"></span>
                    </button>

                    <div className="dropWrapper" style={{ height: "auto" }}>
                      <div className="dropdown">
                        {/* <div className="fieldInput">
                                                    <TextField
                                                        fullWidth
                                                        className='textField'
                                                        id="searchParameter"
                                                        name="searchParameter" 
                                                        type="text"  
                                                        value={formik.values.searchParameter}
                                                        //onBlur={() => filterTutors(formik.values.searchParameter)}
                                                        onChange={(e) => {
                                                            filterTutors()
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </div> */}
                        <Box>
                          <Autocomplete
                            value={subjectVal}
                            onChange={(event, newValue) => {
                              // console.log(newValue);
                              if (typeof newValue === "string") {
                                setSubjectVal({
                                  name: newValue,
                                });
                              } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setSubjectVal({
                                  name: newValue.inputValue,
                                });
                              } else {
                                setSubjectVal(newValue);
                              }
                              //setClassCat("");
                              filterTutors(event.target.innerText, "subject");

                              //On Subject search delete
                              if (!event.target.innerText) {
                                if (classCat) {
                                  dispatch(
                                    searchTutors("", classCat.name)
                                  ).then(() => {
                                    //setTutorsList(searchData.data);
                                    //console.log(subjectVal);
                                  });
                                } else {
                                  dispatch(getTutors("none")).then(() => {
                                    //const tutorsData = useSelector((state) => state.tutors.data);
                                    setTutorsList(tutorsData.data);
                                  });
                                }
                              }
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="subject-selection"
                            options={subjectOptions}
                            // getOptionLabel={ (option: Object | string) => option['name'] || option }
                            getOptionLabel={(subject) => {
                              // Value selected with enter, right from the input
                              if (typeof subject == "string") {
                                return subject;
                              } else if (Array.isArray(subject)) {
                                return "";
                              }
                              // Regular option
                              else {
                                return subject.name;
                              }
                            }}
                            renderOption={(props, subject) => (
                              <li {...props}>{subject.name}</li>
                            )}
                            sx={{ width: "100%" }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                id="subject"
                                name="subject"
                                {...params}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    color: "rgba(0, 0, 0, 0.74)",
                                  },
                                  "& .MuiInputBase-root": {
                                    backgroundColor: "#FFF",
                                    border: "1px solid rgba(0, 0, 0, 0.5)",
                                  },
                                  "& .MuiSelect-select": {
                                    border: "1px solid rgba(0, 0, 0, 0.3)",
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                        <div className="supportText mt-lg-1 mt-1">
                          Search for a specific word or character to help you
                          find your perfect online tutor.
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="accordionItem pt-lg-3 pt-2">
                    <button className="button">
                      <span className="mb-lg-2 mb-1">Search by Class</span>
                      <span className="control"></span>
                    </button>

                    <div className="dropWrapper" style={{ height: "auto" }}>
                      <div className="dropdown">
                        {/* <div className="fieldInput">
                                                    <TextField
                                                        fullWidth
                                                        className='textField'
                                                        id="searchParameter"
                                                        name="searchParameter" 
                                                        type="text"  
                                                        value={formik.values.searchParameter}
                                                        //onBlur={() => filterTutors(formik.values.searchParameter)}
                                                        onChange={(e) => {
                                                            filterTutors()
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </div> */}
                        <Box>
                          <Autocomplete
                            value={classCat}
                            onChange={(event, newValue) => {
                              // console.log(newValue);
                              if (typeof newValue === "string") {
                                setClassCat({
                                  name: newValue,
                                });
                              } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setClassCat({
                                  name: newValue.inputValue,
                                });
                              } else {
                                setClassCat(newValue);
                              }

                              //setSubjectVal("");
                              filterTutors(event.target.innerText, "class");

                              //On Class search delete
                              if (!event.target.innerText) {
                                if (subjectVal) {
                                  dispatch(
                                    searchTutors(subjectVal.name, "")
                                  ).then(() => {
                                    //setTutorsList(searchData.data);
                                    console.log(subjectVal);
                                  });
                                } else {
                                  dispatch(getTutors("none")).then(() => {
                                    //const tutorsData = useSelector((state) => state.tutors.data);
                                    setTutorsList(tutorsData.data);
                                  });
                                }
                              }
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="class-selection"
                            options={classesOptions}
                            // getOptionLabel={ (option: Object | string) => option['name'] || option }
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option == "string") {
                                return option;
                              } else if (Array.isArray(option)) {
                                return "";
                              }
                              // Regular option
                              else {
                                return option.name;
                              }
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option.name}</li>
                            )}
                            sx={{ width: "100%" }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                id="class"
                                name="class"
                                {...params}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    color: "rgba(0, 0, 0, 0.74)",
                                  },
                                  "& .MuiInputBase-root": {
                                    backgroundColor: "#FFF",
                                    border: "1px solid rgba(0, 0, 0, 0.5)",
                                  },
                                  "& .MuiSelect-select": {
                                    border: "1px solid rgba(0, 0, 0, 0.3)",
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                        <div className="supportText mt-lg-1 mt-1">
                          Search for a specific word or character to help you
                          find your perfect online tutor.
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-8 mt-lg-0 mt-5">
            <div id="tutorsWrap" className="tutorsWrap">
              {tutorsList &&
                tutorsList.map((tutor, index) => (
                  <div className="tutorsHld" key={index}>
                    <div className="photo">
                      <img
                        src={tutor.imageUrl}
                        alt=""
                        crossOrigin="anonymous"
                      />
                    </div>
                    <div className="details">
                      <div className="title">{tutor.name}</div>
                      <div className="preamble">{tutor.subjects}</div>
                      <div className="featureWrap">
                        <div className="featureHld">
                          {tutor.yrsOfExperience} Years Experience
                        </div>
                        <div className="featureHld">
                          <img src={Rating} alt="" />
                          <span>{tutor.stars} Star Rating</span>
                        </div>
                        <div className="featureHld">
                          {tutor.hrsDelivered} hours of Lessons Delivered
                        </div>
                      </div>
                      <Button
                        className="hasIcon mainCta"
                        fullWidth
                        type="button"
                        variant="contained"
                        onClick={() => handleTutorOpen(tutor.id)}
                      >
                        View Tutor
                      </Button>
                    </div>
                  </div>
                ))}

              {tutorsList.length > 0 && (
                <div className="w-100 d-flex justify-content-center py-5">
                  <Pagination
                    count={Math.ceil(
                      tutorsData.totalRecords / tutorsData.limit
                    )}
                    onChange={handleChange}
                    variant="outlined"
                    color="primary"
                  />
                </div>
              )}

              {tutorsList.length < 1 && (
                <div className="noResults">
                  No tutors match your search criteria
                </div>
              )}
            </div>
          </div>

          <Modal
            open={tutorModalOpen}
            onClose={handleTutorClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBox" sx={style}>
              <div onClick={handleTutorClose} className="modalHdr closeModal">
                <div className="closeBtn"></div>
              </div>
              <div className="modalInfo row justify-content-between align-items-center">
                <div className="col-lg-5 text-center">
                  <img className="popUpLogo" src={Logo} alt="" />
                  <img
                    className="tutorImg"
                    src={tutorModalData && tutorModalData.imageUrl}
                    alt=""
                    crossOrigin="anonymous"
                  />
                </div>
                <div className="col-lg-7 py-4 py-lg-4">
                  <div className="hdr md dark">
                    {tutorModalData && tutorModalData.name}
                  </div>
                  <div className="preamble">
                    {tutorModalData && tutorModalData.subjects}
                  </div>
                  <div className="featureWrap">
                    <div className="featureHld">
                      {tutorModalData && tutorModalData.yrsOfExperience} Years
                      Experience
                    </div>
                    <div className="featureHld">
                      <img src={Rating} alt="" />
                      <span>
                        {tutorModalData && tutorModalData.stars} Star Rating
                      </span>
                    </div>
                    <div className="featureHld">
                      {tutorModalData && tutorModalData.hrsDelivered} hours of
                      Lessons Delivered
                    </div>
                  </div>
                  <div className="text xs">
                    {tutorModalData && tutorModalData.bio}
                  </div>
                  <a className="noFormat" href="/subject-tutor-request-form">
                    <Button
                      className="hasIcon mainCta extra"
                      fullWidth
                      type="button"
                      variant="contained"
                      onClick={handleSelection}
                    >
                      Request Tutor <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </a>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TutorsPage;
