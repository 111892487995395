import * as React from "react";

import { Button, Icon } from "@mui/material";

import CountUp from "react-countup";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import HeroImage1 from "../assets/img/coding/codingHero1.png";
import HeroImage3 from "../assets/img/coding/codingHero3.png";

import { getCodingGallery } from "../actions/formdata";

import GalleryImage1 from "../assets/img/coding/gallery1.jpg";
import GalleryImage2 from "../assets/img/coding/gallery2.jpg";
import GalleryImage3 from "../assets/img/coding/gallery3.jpg";
import GalleryImage4 from "../assets/img/coding/gallery4.jpg";
import GalleryImage5 from "../assets/img/coding/gallery5.jpg";
import GalleryImage6 from "../assets/img/coding/gallery6.jpg";
import GalleryImage7 from "../assets/img/coding/gallery7.jpg";
import GalleryImage8 from "../assets/img/coding/gallery8.jpg";
import GalleryImage9 from "../assets/img/coding/gallery9.jpg";
import GalleryImage10 from "../assets/img/coding/gallery10.jpg";
import GalleryImage11 from "../assets/img/coding/gallery11.jpg";
import GalleryImage12 from "../assets/img/coding/gallery12.jpg";
import GalleryImage13 from "../assets/img/coding/gallery13.jpg";
import GalleryImage14 from "../assets/img/coding/gallery14.jpg";
import GalleryImage15 from "../assets/img/coding/gallery15.jpg";
import GalleryImage16 from "../assets/img/coding/gallery16.jpg";
import GalleryImage17 from "../assets/img/coding/gallery17.jpg";
import GalleryImage18 from "../assets/img/coding/gallery18.jpg";
import GalleryImage19 from "../assets/img/coding/gallery19.jpg";
import GalleryImage20 from "../assets/img/coding/gallery20.jpg";
import GalleryImage21 from "../assets/img/coding/gallery21.jpg";
import GalleryImage22 from "../assets/img/coding/gallery22.jpg";
import GalleryImage23 from "../assets/img/coding/gallery23.jpg";
import GalleryImage24 from "../assets/img/coding/gallery24.jpg";
import GalleryImage25 from "../assets/img/coding/gallery25.jpg";
import GalleryImage26 from "../assets/img/coding/gallery26.jpg";
import GalleryImage27 from "../assets/img/coding/gallery27.jpg";
import GalleryImage28 from "../assets/img/coding/gallery28.jpg";
import GalleryImage29 from "../assets/img/coding/gallery29.jpg";
import GalleryImage30 from "../assets/img/coding/gallery30.jpg";
import GalleryImage31 from "../assets/img/coding/gallery31.jpg";
import GalleryImage32 from "../assets/img/coding/gallery32.jpg";
import GalleryImage33 from "../assets/img/coding/gallery33.jpg";
import GalleryImage34 from "../assets/img/coding/gallery34.jpg";
import GalleryImage35 from "../assets/img/coding/gallery35.jpg";
import GalleryImage36 from "../assets/img/coding/gallery36.jpg";
import GalleryImage37 from "../assets/img/coding/gallery37.jpg";
import GalleryImage38 from "../assets/img/coding/gallery38.jpg";
import GalleryImage39 from "../assets/img/coding/gallery39.jpg";
import GalleryImage40 from "../assets/img/coding/gallery40.jpg";
import GalleryImage41 from "../assets/img/coding/gallery41.jpg";
import GalleryImage42 from "../assets/img/coding/gallery42.jpg";
import GalleryImage43 from "../assets/img/coding/gallery43.jpg";
import GalleryImage44 from "../assets/img/coding/gallery44.jpg";
import GalleryImage45 from "../assets/img/coding/gallery45.jpg";
import GalleryImage46 from "../assets/img/coding/gallery46.jpg";
import GalleryImage47 from "../assets/img/coding/gallery47.jpg";
import GalleryImage48 from "../assets/img/coding/gallery48.jpg";
import GalleryImage49 from "../assets/img/coding/gallery49.jpg";
import GalleryImage50 from "../assets/img/coding/gallery50.jpg";
import GalleryImage51 from "../assets/img/coding/gallery51.jpg";
import GalleryImage52 from "../assets/img/coding/gallery52.jpg";

const Coding = () => {
  const dispatch = useDispatch();
  // const stateValues = useSelector((state) => state);
  useEffect(() => {
    dispatch(getCodingGallery());
  }, []);

  const gallery = useSelector((state) => state);
  console.log(gallery);

  return (
    <div className="page-content bkg-green-light pgx pgy">
      <div className="subPages row justify-content-between align-items-start">
        <div className="col-lg-6">
          <div className="hdr xl mb-lg-3">Coding & Robotics</div>
          <div className="only-mobile double py-3 hasBlur justify-content-between align-items-center">
            <div className="box-card md clr-pink">
              <img src={HeroImage1} alt="" />
            </div>
            <div className="box-card lg clr-purple">
              <img className="red" src={HeroImage3} alt="" />
            </div>
          </div>
          <div className="text">
            <p>
              Nigenius provides schools with qualified and experienced STEAM
              tutors for specialized courses like Coding and Robotics.
            </p>
            <h4>Why choose us as your coding and Robotics partner ?</h4>
            <ul>
              <li>Well trained and experienced Coding and Robotics tutors.</li>
              <li>World, class Future - ready STEAM Curriculum.</li>
              <li>
                We guarantee one innovative project exhibition at the end of
                every school term.
              </li>
              <li>State of the Art Robotics kits.</li>
            </ul>
          </div>
          <div className="btnHld mt-lg-5">
            <a className="noFormat" href="/coding-tutor-school-request-form">
              <Button
                className="hasIcon mainCta caps"
                fullWidth
                type="button"
                variant="contained"
              >
                Request Tutors <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </a>

            <Button
              className="transCta"
              fullWidth
              type="button"
              variant="outlined"
              onClick={(e) => {
                let target = document.getElementById("gallery");
                e.preventDefault();
                target && target.scrollIntoView({ behavior: "smooth" });
              }}
            >
              View Gallery
            </Button>
          </div>
        </div>
        <div className="col-lg-5 only-desktop double hasBlur justify-content-between align-items-center">
          <div className="box-card md clr-pink">
            <img src={HeroImage1} alt="" />
          </div>
          <div className="box-card lg clr-purple">
            <img className="red" src={HeroImage3} alt="" />
          </div>
        </div>
      </div>

      <div className="statsWrap bkg-dark-blue">
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={7} />
          </div>
          <div className="statTitle">Number of Schools </div>
        </div>
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={1500} separator="," />
          </div>
          <div className="statTitle">Tutored Students </div>
        </div>
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={10000} separator="," />
          </div>
          <div className="statTitle">Hours Tutored</div>
        </div>
        <div className="statsHld">
          <div className="statNo">
            <CountUp end={50} />
          </div>
          <div className="statTitle">Expert Tutors</div>
        </div>
      </div>

      <div className="row" id="gallery">
        <div className="hdr lg dark text-center">Tutor Gallery</div>

        <div className="galleryWrap">
          <img className="border-pink" src={GalleryImage11} alt="" />
          <img className="border-pink" src={GalleryImage12} alt="" />
          <img className="border-pink" src={GalleryImage13} alt="" />
          <img className="border-pink" src={GalleryImage1} alt="" />
          <img className="border-pink" src={GalleryImage4} alt="" />

          <img className="border-purple" src={GalleryImage2} alt="" />
          <img className="border-purple" src={GalleryImage3} alt="" />
          <img className="border-purple" src={GalleryImage4} alt="" />
          <img className="border-purple" src={GalleryImage5} alt="" />
          <img className="border-purple" src={GalleryImage6} alt="" />
          <img className="border-orange" src={GalleryImage7} alt="" />
          <img className="border-orange" src={GalleryImage8} alt="" />
          <img className="border-orange" src={GalleryImage9} alt="" />
          <img className="border-yellow" src={GalleryImage10} alt="" />
          <img className="border-yellow" src={GalleryImage14} alt="" />
          <img className="border-yellow" src={GalleryImage15} alt="" />
          <img className="border-yellow" src={GalleryImage16} alt="" />
          <img className="border-yellow" src={GalleryImage17} alt="" />
          <img className="border-purple" src={GalleryImage18} alt="" />
          <img className="border-purple" src={GalleryImage19} alt="" />
          <img className="border-purple" src={GalleryImage20} alt="" />
          <img className="border-purple" src={GalleryImage21} alt="" />
          <img className="border-purple" src={GalleryImage22} alt="" />
          <img className="border-orange" src={GalleryImage23} alt="" />
          <img className="border-orange" src={GalleryImage24} alt="" />
          <img className="border-orange" src={GalleryImage25} alt="" />
          <img className="border-orange" src={GalleryImage26} alt="" />
          <img className="border-orange" src={GalleryImage27} alt="" />
          <img className="border-orange" src={GalleryImage28} alt="" />
          <img className="border-yellow" src={GalleryImage29} alt="" />
          <img className="border-yellow" src={GalleryImage30} alt="" />
          <img className="border-yellow" src={GalleryImage31} alt="" />
          <img className="border-yellow" src={GalleryImage32} alt="" />
          <img className="border-yellow" src={GalleryImage33} alt="" />
          <img className="border-yellow" src={GalleryImage34} alt="" />
          <img className="border-purple" src={GalleryImage35} alt="" />
          <img className="border-purple" src={GalleryImage36} alt="" />
          <img className="border-purple" src={GalleryImage37} alt="" />
          <img className="border-orange" src={GalleryImage38} alt="" />
          <img className="border-orange" src={GalleryImage39} alt="" />
          <img className="border-orange" src={GalleryImage40} alt="" />
          <img className="border-orange" src={GalleryImage41} alt="" />
          <img className="border-orange" src={GalleryImage42} alt="" />
          <img className="border-yellow" src={GalleryImage43} alt="" />
          <img className="border-yellow" src={GalleryImage44} alt="" />
          <img className="border-yellow" src={GalleryImage45} alt="" />
          <img className="border-yellow" src={GalleryImage46} alt="" />
          <img className="border-purple" src={GalleryImage47} alt="" />
          <img className="border-purple" src={GalleryImage48} alt="" />
          <img className="border-purple" src={GalleryImage49} alt="" />
          <img className="border-purple" src={GalleryImage50} alt="" />
          <img className="border-purple" src={GalleryImage51} alt="" />
          <img className="border-purple" src={GalleryImage52} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Coding;
